import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomTooltipText from 'components/Customs/CustomTooltipText'

// MUIS
import IconButton from '@mui/material/IconButton'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'

const TooltipIconButton = (props) => {
  const { 
    title,
    onClick,
    className,
    children,
    ...otherIconButtonProps
  } = props

  const layoutClasses = useLayoutStyles()

  return (
    <CustomTooltipText
      title={title} 
      placement='bottom'
    >
      <IconButton 
        className={`${layoutClasses.tooltipIconContainer} ${className}`}
        onClick={onClick}
        {...otherIconButtonProps}
      >
        {children}
      </IconButton>
    </CustomTooltipText>
  )
}

TooltipIconButton.defaultProps = {
  title: '',
  className: '',
}

TooltipIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default TooltipIconButton