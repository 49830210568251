import axios from 'axios'

const baseURL = process.env.REACT_APP_TMMIN_BASE_API
const headers = { 
  'Content-Type': 'application/json', 
  'x-apihub-key' : process.env.REACT_APP_TMMIN_X_API_HUB_KEY,
}

export default axios.create({
  baseURL: baseURL,
  headers: headers,
})

export const axiosPrivate = axios.create({
  baseURL: baseURL,
  headers: headers,
  // withCredentials: true,
})