// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    color: theme.palette.text.primary,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.additional.iconButtonHover,
    },
  },
  flag: {
    width: 20,
    height: 15,
  },
  languageItem: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: 8,
  },
}))

export default useStyles
