// CONSTANTS
import { values } from 'constants/values'

const getKey = () => {
  const dashboardType = values.dashboardType.toLowerCase()

  return {
    keyLanguage: `blockchain-${dashboardType}-language`,
    keyUserProfile: `blockchain-${dashboardType}-user`,
    keyTheme: `blockchain-${dashboardType}-theme`,
  }
}

// LANGUAGE
export const setLanguageToLocalStorage = (inputLanguage) => {
  localStorage.setItem(getKey().keyLanguage, inputLanguage)
}

export const readLanguageFromLocalStorage = () => { 
  return localStorage.getItem(getKey().keyLanguage) 
    ? JSON.parse(localStorage.getItem(getKey().keyLanguage)) 
    : values.languageList[0]
}

export const removeLanguageFromLocalStorage = () => {
  localStorage.removeItem(getKey().keyLanguage)
}

// USER PROFILE
export const setUserProfileToLocalStorage = (inputUserObject) => {
  localStorage.setItem(getKey().keyUserProfile, inputUserObject)
}

export const readUserProfileFromLocalStorage = () => { 
  return localStorage.getItem(getKey().keyUserProfile) 
    ? JSON.parse(localStorage.getItem(getKey().keyUserProfile)) 
    : {}
}

export const removeUserProfileFromLocalStorage = () => {
  localStorage.removeItem(getKey().keyUserProfile)
}

// THEME
export const setThemeToLocalStorage = (inputTheme) => {
  localStorage.setItem(getKey().keyTheme, inputTheme)
}

export const readThemeFromLocalStorage = () => { 
  return localStorage.getItem(getKey().keyTheme) 
    ? localStorage.getItem(getKey().keyTheme) 
    : values.themeLight
}

export const removeThemeFromLocalStorage = () => {
  localStorage.removeItem(getKey().keytheme)
}