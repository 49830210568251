// ROUTES
import { authenticationRouteList } from './authentication'
import { freeRouteList } from './free'
import { privateRouteList } from './private'

let routes = [
  // AUTHENTICATION
  ...authenticationRouteList,
  // PRIVATE
  ...privateRouteList,
  // FREE
  ...freeRouteList,
]

routes = routes.filter(item => item)

export default routes