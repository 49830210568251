// CONSTANTS
import { values } from 'constants/values'

// MUI ICONS
import IconChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined'
import IconEnergySavingsLeafOutlined from '@mui/icons-material/EnergySavingsLeafOutlined'
import IconHistoryOutlined from '@mui/icons-material/HistoryOutlined'
import IconHomeOutlined from '@mui/icons-material/HomeOutlined'
// import IconReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined'
import IconRouteOutlined from '@mui/icons-material/RouteOutlined'
import IconSecurityOutlined from '@mui/icons-material/SecurityOutlined'
import IconTextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined'
import IconTimelineOutlined from '@mui/icons-material/TimelineOutlined'

let drawerNavigationList = [
  {
    type: 'single',
    icon: IconHomeOutlined,
    title: 'Dashboard',
    path: '/dashboard',
  },
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka) && {
    type: 'single',
    icon: IconEnergySavingsLeafOutlined,
    title: 'Carbon Footprint',
    path: '/carbon-footprint',
  },
  {
    type: 'single',
    icon: IconTimelineOutlined,
    title: 'Traceability',
    path: '/traceability',
  },
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka) && 
  {
    type: 'single',
    icon: IconRouteOutlined,
    title: 'Trackability',
    path: '/trackability',
  },
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeTmmin) && {
    type: 'single',
    icon: IconSecurityOutlined,
    title: 'Privacy',
    path: '/privacy',
  },
  values.dashboardType !== values.dashboardTypePidi &&
  {
    type: 'single',
    icon: IconHistoryOutlined,
    title: 'History',
    path: '/history',
  },
  values.dashboardType === values.dashboardTypePublic && {
    type: 'collection',
    title: 'Forms',
    icon: IconTextSnippetOutlined,
    path: '/forms',
    children: [
      {
        path: '/forms/items',
        title: 'Items',
      },
      {
        path: '/forms/actors',
        title: 'Actors',
      },
      {
        path: '/forms/touch-points',
        title: 'Touch Points',
      },
      {
        path: '/forms/workflows',
        title: 'Workflows',
      },
    ],
  },
  values.dashboardType === values.dashboardTypePublic && {
    type: 'single',
    icon: IconChangeCircleOutlined,
    title: 'Transactions',
    path: '/transactions',
  },
  // {
  //   type: 'single',
  //   icon: IconReceiptLongOutlined,
  //   title: 'Orders',
  //   path: '/orders',
  // },
]

drawerNavigationList = drawerNavigationList.filter(item => item)

export default drawerNavigationList