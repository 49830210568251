import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import Drawer from 'components/Drawer/Drawer'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext'

// FULL SCREEN
import { FullScreen } from 'react-full-screen'

// HOOKS
import useTmminAxiosPrivate from 'hooks/useTmminAxiosPrivate'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { getUserAccountList } from 'services/blockchainCore/accounts'
import { 
  getUserRole,
  tmminGetUserProfile,
} from 'services/tmmin/userMgmt'
import { tmminGetSubscriptionPackage } from 'services/tmmin/blockchain'

// STYLES
import useStyles from './privateUseStyles'

// UTILS
import { setUserProfileToLocalStorage } from 'utils/localStorage'

const Private = (props) => {
  const { children } = props

  const classes = useStyles()

  const { 
    auth, setAuth, 
    breakpointType,
  } = useContext(AllPagesContext)

  const { 
    isDrawerExpanded, 
    handleFullScreen,
  } = useContext(LayoutPrivateContext)

  const tmminAxiosPrivate = useTmminAxiosPrivate()

  const getContentPaddingLeft = () => {
    if (breakpointType === 'lg' || breakpointType === 'xl') {
      if (isDrawerExpanded) return values.drawerWidthOnExpand
      else return values.drawerWidthOnCollapse
    }
    else return 0
  }

  const loadUserRoleAndAccountData = async (inputIsMounted, inputAbortController) => {
    // LOAD USER ROLE DATA
    const resultUserRole = await getUserRole(
      tmminAxiosPrivate,
      inputAbortController.signal,
      auth?.user?.username,
    )

    // LOAD SUBSCRIPTION DATA
    const resultSubscriptionPackageData = await tmminGetSubscriptionPackage(
      tmminAxiosPrivate, 
      inputAbortController.signal,
      auth?.user?.username,
    )

    // LOAD USER PROFILE
    const resultUserProfile = await tmminGetUserProfile(
      tmminAxiosPrivate,
      inputAbortController.signal,
    )

    if (
      resultUserRole.status === 200 && 
      resultSubscriptionPackageData.status === 200 && 
      resultUserProfile.status === 200 &&
      inputIsMounted
    ) {
      const userRoleData = resultUserRole.data.data

      // LOAD USER ACCOUNT DATA
      const resultAccount = await getUserAccountList(
        inputAbortController.signal,
        0,
        100,
        userRoleData.companyCd,
      )
  
      if (resultAccount.status === 200 && inputIsMounted) {
        const profileData = resultUserProfile.data.data
        const accountData = resultAccount.data.data.rows[0]

        const newUserObject = {
          ...auth,
          userRole: {
            email: userRoleData.email,
            companyCd: userRoleData.companyCd,
            roleCd: userRoleData.roleVOList[0].roleCd,
            roleName: userRoleData.roleVOList[0].roleName,
          },
          subscription: {
            package_name: profileData.userName !== 'superadmin' 
              ? resultSubscriptionPackageData.data.data[0].package_name
              : 'TMMIN Internal',
          },
          userProfile: {
            personName: profileData.personName,
            userName: profileData.userName,
          },
          account: {
            owner: accountData?.owner ?? '-',
            address: accountData?.address ?? '-',
          },
        }
  
        setUserProfileToLocalStorage(JSON.stringify(newUserObject))
  
        setAuth(newUserObject)
      }
    }
  }

  const kedairekaAndPidiSetUserData = () => {
    const userObject = {
      ...auth,
      userRole: {
        email: `${auth?.user?.username}@${values.dashboardType.toLowerCase()}.com`,
        companyCd: values.dashboardType,
        roleCd: 'SUPER_ADMIN',
        roleName: 'Super Admin',
      },
      subscription: {
        package_name: `${values.dashboardType} Internal`,
      },
      userProfile: {
        personName: auth?.user?.username,
        userName: auth?.user?.username,
      },
      account: {
        owner: values.dashboardType,
        address: '0xfe3b557e8fb62b89f4916b721be55ceb828dbd73',
      }
    }

    setUserProfileToLocalStorage(JSON.stringify(userObject))
  
    setAuth(userObject)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    if (values.dashboardType === values.dashboardTypeTmmin) loadUserRoleAndAccountData(isMounted, abortController)
    else if (
      values.dashboardType === values.dashboardTypePidi ||
      values.dashboardType === values.dashboardTypeKedaireka
    ) kedairekaAndPidiSetUserData()

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [auth?.user])

  return (
    <FullScreen handle={handleFullScreen}>
      <Box className={`${classes.root} no-zoom`}>
        {/* DRAWER */}
        <Box component='nav'>
          <Drawer/>
        </Box>

        <Box 
          component='main'
          className={`${classes.contentContainer} zoom`}
          sx={{ paddingLeft: getContentPaddingLeft() }}
        >
          {/* APP BAR */}
          <AppBar/>

          {/* CONTENT */}
          <Box className={classes.mainContent}>
            {children}
          </Box>
        </Box>
      </Box>
    </FullScreen>
  )
}

Private.defaultProps = {}

Private.propTypes = { children: PropTypes.node.isRequired }

export default Private
