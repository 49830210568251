import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// CUSTOM COMPONENTS
import CustomTooltipContainer from 'components/Customs/CustomTooltipContainer'

// MUIS
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconHomeRounded from '@mui/icons-material/HomeRounded'
import IconTagRounded from '@mui/icons-material/TagRounded'
import IconVerifiedRounded from '@mui/icons-material/VerifiedRounded'

// STYLES
import useStyles from './tooltipUserProfileUseStyles'

// UTILS
import { signOutUser } from 'utils/authentication'

// UTILS
import { getTmminPricingList } from 'utils/dummy'

const TooltipUserProfile = () => {
  const classes = useStyles()

  const theme = useTheme()

  const { 
    auth, setAuth, 
    breakpointType,
  } = useContext(AllPagesContext)

  const personName = auth?.userProfile?.personName ?? '-'
  const userRole = auth?.userRole?.roleName ?? '-'

  const getBadgeColor = (inputPlan) => {
    if (!inputPlan || inputPlan === '-') return theme.palette.action.active
    else {
      const selectedPricing = getTmminPricingList(theme).filter(item => item.title === inputPlan)
      return selectedPricing.length > 0 ? selectedPricing[0].color : theme.palette.action.active
    }
  }

  const tooltipList = [
    {
      type: 'plan',
      icon: (
        <IconVerifiedRounded 
          fontSize='small'
          sx={{ color: getBadgeColor(auth?.subscription?.package_name) }}
        />
      ),
      text: auth?.subscription?.package_name ?? '-',
    },
    {
      type: 'owner',
      icon: (
        <IconHomeRounded 
          fontSize='small'
          color='default'
        />
      ),
      text: auth?.account?.owner ?? '-',
    },
    {
      type: 'address',
      icon: (
        <IconTagRounded 
          fontSize='small'
          color='default'
        />
      ),
      text: auth?.account?.address ?? '-',
    },
  ]

  return (
    <CustomTooltipContainer
      placement='bottom-end'
      title={
        <Paper className={classes.tooltipPaper}>
          {/* AVATAR */}
          <Avatar className={classes.tooltipAvatar}>
            {auth?.user?.username[0].toUpperCase()}
          </Avatar>

          {/* PERSON NAME */}
          <Typography 
            variant='body2'
            className='fontWeight600'
          >
            {personName}
          </Typography>

          {/* ROLE */}
          <Typography 
            variant='caption'
            className={classes.tooltipRole}
          >
            {userRole}
          </Typography>

          {/* USER DETAIL */}
          <List 
            disablePadding
            className='widthFullWidth' 
          >
            {tooltipList.map((item, index) => (
              item.text !== '-' &&
              <ListItem
                key={index}
                disablePadding
              >
                {/* ICON */}
                <ListItemIcon className={classes.tooltipItemIcon}>
                  {item.icon}
                </ListItemIcon>

                {/* TEXT */}
                <ListItemText 
                  primary={
                    <Typography
                      variant='body2'
                      className={item.type === 'plan' ? 'fontWeight600' : ''}
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>

          {/* LOG OUT BUTTON */}
          <Button
            variant='filled'
            color='primary'
            fullWidth
            onClick={() => signOutUser(setAuth)}
            className={classes.tooltipLogOutButton}
          >
            Log Out
          </Button>
        </Paper>
      }
    >
      <Stack 
        direction='row'
        spacing='16px'
        className={classes.userProfileContainer}
      >
        {/* AVATAR */}
        <Avatar 
          className={classes.userProfileAvatar}
          variant='rounded'
        >
          {personName[0].toUpperCase()}
        </Avatar>

        {(breakpointType === 'lg' || breakpointType === 'xl') &&
        <Stack className={classes.userProfileTextContainer}>
          <Stack 
            direction='row'
            justifyContent='center'
            spacing='4px'
          >
            <IconVerifiedRounded 
              fontSize='small'
              sx={{ color: getBadgeColor(auth?.subscription?.package_name) }}
            />

            {/* PERSON NAME */}
            <Typography
              variant='body2'
              className={`${classes.userProfileText} fontWeight600`}
              noWrap
            >
              {personName}
            </Typography>
          </Stack>

          {/* ROLE */}
          <Typography
            variant='body2'
            className={classes.userProfileText}
            noWrap
          >
            {userRole}
          </Typography>
        </Stack>}
      </Stack>
    </CustomTooltipContainer>
  )
}

export default TooltipUserProfile