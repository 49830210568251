import { encode } from 'base-64'

// APIS
import axiosTmmin from 'apis/axiosTmmin'

export const tmminPostSignInUser = async (inputUsername, inputPassword) => {
  try {
    const response = await axiosTmmin.post(
      '/identity/v1/login',
      {
        username: inputUsername,
        password: encode(inputPassword),
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const tmminPostRefreshToken = async (
  inputUsername, 
  inputRefreshToken,
) => {
  try {
    const response = await axiosTmmin.post(
      '/identity/v1/refresh-token',
      {
        username: inputUsername,
        refreshToken: inputRefreshToken,
      },
      // { withCredentials: true },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}