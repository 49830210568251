export const addRowIdToTableData = (inputTableData) => {
  const output = inputTableData.map((item, index) => {
    return {
      ...item,
      id: index + 1,
    }
  })
  
  return output
}

export const getStringAvatar = (inputString) => {
  if (!inputString || inputString.split(' ').length < 1) return null
  else if (inputString.split(' ').length === 1) return inputString[0]
  else if (inputString.split(' ').length > 1) return `${inputString.split(' ')[0][0]}${inputString.split(' ')[1][0]}`
}

export const getColorAvatar = (
  inputTheme,
  inputString,
) => {
  const themeList = [
    // PRIMARY
    inputTheme.palette.primary.main,
    inputTheme.palette.primary.dark,
    // SECONDARY
    inputTheme.palette.secondary.main,
    inputTheme.palette.secondary.dark,
    // ERROR
    inputTheme.palette.error.main,
    inputTheme.palette.error.dark,
    // SUCCESS
    inputTheme.palette.success.main,
    inputTheme.palette.success.dark,
    // WARNING7
    inputTheme.palette.warning.main,
    inputTheme.palette.warning.dark,
    // INFO
    inputTheme.palette.info.main,
    inputTheme.palette.info.dark,
  ]

  if (!inputString || inputString === '') return 'default'
  else {
    const selectedIndex = (inputString[0].charCodeAt(0) - 65) % themeList.length
    return themeList[selectedIndex]
  }
}

export const getPidiAbnormalNotificationBody = (inputType) => {
  return {
    'material_number': 'MHFAB1BY1P3016826',
    'point_code': 'PT_PDI-VEHICLE_001',
    'point_cd_master': 'PT_PDI-VEHICLE_001',
    'workflow_code': 'WF_VEHICLE',
    'company_code': ['TMMIN'],
    'use_case_code': 'UC_VEHICLE',
    'data': {
      'mat_no': 'MHFAB1BY1P3016826',
      'wf_cd': 'WF_VEHICLE',
      'wf_name': 'WF Vehicle',
      'point_cd': 'PT_PDI-VEHICLE_001',
      'point_cd_master': 'PT_PDI-VEHICLE_001',
      'point_name': 'TMMIN Vehicle',
      'seq_point': 4,
      'vin_no': 'MHFAB1BY1P3016826',
      'katashiki_cd': 'W101LE-LBGFX-3C',
      'input_dt': 1663290556000,
      'type_cd': 'VEHICLE',
      'qr_cd': inputType === 'qr_code' ? 'NUMBER-50' : '3016826',
      'qty': 1
    },
    'prev_tracks': [
      {
        'material_number': 'MHFAB1BY1P3016826',
        'point_code': 'PT_PDI-VEHICLE_001',
        'point_cd_master': 'PT_PDI-VEHICLE_001',
        'workflow_code': 'WF_VEHICLE',
        'company_code': ['TMMIN'],
        'use_case_code': 'UC_VEHICLE',
        'data': {
          'mat_no': 'MHFAB1BY1P3016826',
          'wf_cd': 'WF_VEHICLE',
          'wf_name': 'WF Vehicle',
          'point_cd': 'PT_PDI-VEHICLE_001',
          'point_cd_master': 'PT_PDI-VEHICLE_001',
          'point_name': 'TMMIN Vehicle',
          'seq_point': 3,
          'part_id': null,
          'part_no': 'MHFAB1BY1P',
          'engine_no': null,
          'katashiki_cd': null,
          'vin_no': null,
          'input_dt': 'Tuesday, March 1, 2022 8:10:31 PM GMT+07:00',
          'qr_cd': '3016826',
          'type_cd': 'PART',
          'qty': inputType === 'quantity' ? 2 : 1
        }
      },
      {
        'material_number': 'MHFAB1BY1P3016826',
        'point_code': 'PT_PDI-VEHICLE_002',
        'point_cd_master': 'PT_PDI-VEHICLE_002',
        'workflow_code': 'WF_VEHICLE',
        'company_code': ['TMMIN'],
        'use_case_code': 'UC_VEHICLE',
        'data': {
          'mat_no': 'MHFAB1BY1P3016826',
          'wf_cd': 'WF_VEHICLE',
          'wf_name': 'WF Vehicle',
          'point_cd': 'PT_PDI-VEHICLE_001',
          'point_cd_master': 'PT_PDI-VEHICLE_001',
          'point_name': 'TMMIN Vehicle',
          'seq_point': 2,
          'part_id': null,
          'part_no': 'MHFAB1BY1P',
          'engine_no': null,
          'katashiki_cd': null,
          'vin_no': null,
          'input_dt': 'Tuesday, March 1, 2022 8:10:31 PM GMT+07:00',
          'qr_cd': '3016826',
          'type_cd': 'PART',
          'qty': 1
        }
      },
      {
        'material_number': 'MHFAB1BY1P3016826',
        'point_code': 'PT_PDI-VEHICLE_003',
        'point_cd_master': 'PT_PDI-VEHICLE_003',
        'workflow_code': 'WF_VEHICLE',
        'company_code': ['TMMIN'],
        'use_case_code': 'UC_VEHICLE',
        'data': {
          'mat_no': 'MHFAB1BY1P3016826',
          'wf_cd': 'WF_VEHICLE',
          'wf_name': 'WF Vehicle',
          'point_cd': 'PT_PDI-VEHICLE_001',
          'point_cd_master': 'PT_PDI-VEHICLE_001',
          'point_name': 'TMMIN Vehicle',
          'seq_point': 1,
          'part_id': null,
          'part_no': 'MHFAB1BY1P',
          'engine_no': null,
          'katashiki_cd': null,
          'vin_no': null,
          'input_dt': 'Tuesday, March 1, 2022 8:10:31 PM GMT+07:00',
          'qr_cd': '3016826',
          'type_cd': 'PART',
          'qty': 1
        }
      }
    ]
  }
}

export const getTmminPricingList = (inputTheme) => {
  return [
    {
      title: 'Free',
      color: inputTheme.palette.info.main,
      price: 'Free',
      featureList: [
        'Blockchain API',
        '7 days subscription',
      ],
    },
    {
      title: 'Business',
      color: inputTheme.palette.secondary.main,
      price: 15,
      featureList: [
        'Real Time Monitoring',
        'Supply Chain Traceability',
        'Blockchain API',
        'Alert Monitoring',
        'Dashboard',
      ],
    },
    {
      title: 'Business Plus',
      color: inputTheme.palette.primary.dark,
      price: 25,
      featureList: [
        'Real Time Monitoring',
        'Supply Chain Traceability',
        'Blockchain API',
        'Alert Monitoring',
        'Dashboard',
        'Reporting',
        'Compliance',
        'Data Privacy',
        'Data Update',
      ],
    },
    {
      title: 'Enterprise',
      color: inputTheme.palette.primary.main,
      price: 50,
      featureList: [
        'Real Time Monitoring',
        'Supply Chain Traceability',
        'Blockchain API',
        'Alert Monitoring',
        'Dashboard',
        'Reporting',
        'Compliance',
        'Data Privacy',
        'Data Update',
        'Smart Payment',
        'On Site Node Storage',
        'ERP API Solution',
        'NFT/Product Authentication',
      ],
    },
  ]
}

export const randomAddressGenerator = () => {
  return `0x${(Math.random() + 1).toString(36)}`
}