export const tmminGetTraceabilityList = async (
  inputAxiosPrivate,
  inputSignal,
  // TO DO: STRINGIFY
  inputCategory,
  inputPageNumber,
  inputPageSize,
  inputSearchBy,
  inputSearch,
) => {
  let completeUrl = `/blockchain/v1/${inputCategory.toLowerCase()}?pageNo=${inputPageNumber}&pageSize=${inputPageSize}`
  
  let searchBy
  if (inputSearchBy.toLowerCase() === 'vin no') searchBy = 'vinNo'
  else if (inputSearchBy.toLowerCase() === 'katashiki code') searchBy = 'katashikiCd'
  else if (inputSearchBy.toLowerCase() === 'part number') searchBy = 'partNo'
  else if (inputSearchBy.toLowerCase() === 'part id') searchBy = 'partId'

  completeUrl = `${completeUrl}&${searchBy}=${inputSearch}&finishFlag=true`

  try {
    const response = await inputAxiosPrivate.get(
      completeUrl,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const tmminGetSubscriptionPackage = async (
  inputAxiosPrivate,
  inputSignal,
  inputUsername,
) => {
  let completeUrl = `/blockchain/v1/subscription-package/${inputUsername}`

  try {
    const response = await inputAxiosPrivate.get(
      completeUrl,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}