import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import TooltipIconButton from 'components/TooltipIconButton/TooltipIconButton'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext'

// CUSTOM COMPONENTS
import CustomTooltipContainer from 'components/Customs/CustomTooltipContainer'

// MUIS
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconNotificationsNoneRounded from '@mui/icons-material/NotificationsNoneRounded'
import IconVisibilityRounded from '@mui/icons-material/VisibilityRounded'

// SERVICES
import { 
  getNotificationList, 
  postReadNotificationList,
} from 'services/blockchainContract/notification'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './tooltipNotificationListUseStyles'

const MenuNotificationList = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()

  const { auth } = useContext(AllPagesContext)
  const { mustReloadLayout, setMustReloadLayout } = useContext(LayoutPrivateContext)

  const [ notificationList, setNotificationList ] = useState([])
  const [ notificationCount, setNotificationCount ] = useState(0)

  const loadNotificationListData = async (inputIsMounted, inputAbortController) => {
    const resultNotificationList = await getNotificationList(
      inputAbortController.signal,
      auth?.accessToken,
      {
        offset: 0,
        limit: 25,
        read: false,
      },
    )

    if (resultNotificationList.status === 200 && inputIsMounted) {
      setNotificationList(resultNotificationList.data.data.data)
      setNotificationCount(resultNotificationList.data.data.count)
      setMustReloadLayout(false)
    }
  }

  const handleMarkAsReadButtonClick = async (inputIdList) => {
    const abortController = new AbortController()

    const resultReadNotificationList = await postReadNotificationList(
      abortController.signal,
      auth?.accessToken,
      { id: inputIdList },
    )

    if (resultReadNotificationList.status === 200) setMustReloadLayout(true)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadNotificationListData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [auth?.accessToken, mustReloadLayout])

  return (
    <CustomTooltipContainer
      placement='bottom-start'
      title={
        <Paper className={classes.tooltipRoot}>
          {/* TITLE */}
          <Typography
            variant='subtitle1'
            className={classes.tooltipTitle}
          >
            Notifications
          </Typography>

          {notificationCount > 0 ?
            <>
              {/* NOTIFICATION LIST */}
              <Stack 
                rowGap='12px'
                padding='16px 16px 0px'
                maxHeight='344px'
                overflow='auto'
              >
                {notificationList.map((item, index) => (
                  <MenuItem 
                    key={index}
                    className={classes.notificationItem}
                  >
                    {/* TITLE */}
                    <ListItemText 
                      className={classes.notificationItemTexts}
                      primary={
                        <Typography 
                          className='fontWeight600'
                          noWrap
                        >
                          {item.title}
                        </Typography>
                      }
                      secondary={
                        <Typography noWrap>
                          {item.username}
                        </Typography>
                      }
                    />

                    {/* READ STATUS OR ACTION */}
                    {item.readAt ?
                      // HAS BEEN READ TEXT
                      <Typography
                        variant='body2'
                        color='primary'
                        className='fontWeight600'
                      >
                        Validated
                      </Typography> :
                      // MARK AS READ BUTTON
                      <TooltipIconButton 
                        title='Mark as checked'
                        onClick={() => handleMarkAsReadButtonClick([item.id])}
                      >
                        <IconVisibilityRounded/>
                      </TooltipIconButton>
                    }
                  </MenuItem>
                ))}
              </Stack>

              {/* MENU ACTIONS */}
              <Stack
                direction='row'
                justifyContent='flex-end'
                padding='16px'
              >
                {/* CHECK ALL BUTTON */}
                <Button
                  variant='contained'
                  onClick={() => handleMarkAsReadButtonClick(notificationList.map(item => item.id))}
                >
                  Validate All
                </Button>
              </Stack>
            </> :
            <Typography
              variant='subtitle1'
              className={classes.noNotificationText}
            >
              No Notifications
            </Typography>
          }
        </Paper>
      }
    >
      <IconButton className={`${classes.avatarRoot} ${layoutClasses.tooltipIconContainer}`}>
        <Badge 
          badgeContent={notificationCount}
          className={classes.avatarBadge}
        >
          <IconNotificationsNoneRounded fontSize='small'/>
        </Badge>
      </IconButton>
    </CustomTooltipContainer>
  )
}

export default MenuNotificationList