// DATE AND TIME
import moment from 'moment'

const dashboardType = process.env.REACT_APP_DASHBOARD_TYPE
const dashboardTypePublic= 'PUBLIC'
const dashboardTypeTmmin= 'TMMIN'
const dashboardTypePidi= 'PIDI' 
const dashboardTypeKedaireka = 'KEDAIREKA'

export const values = {
  // APP BAR
  privateAppBarHeight: 80,
  landingAppBarHeight: 94,

  // BLOCKCHAIN STATUS
  blockchainStatusList: [ 'Validated', 'Not Validated' ],

  // DATE RANGE PICKER
  dateRangeoptionList: [
    {
      title: 'Choose Period',
      startDate: '',
      endDate: '',
    },
    {
      title: 'Today',
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
    {
      title: 'Last 7 days',
      startDate: moment().subtract(7, 'days').startOf('days').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
    {
      title: 'Last 30 days',
      startDate: moment().subtract(30, 'days').startOf('days').toDate(),
      endDate: moment().endOf('day').toDate(),
    },
    {
      title: 'This month',
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
    },
  ],

  // DRAWER
  drawerWidthOnExpand: '280px',
  drawerWidthOnCollapse: '92px',

  // DURATION LIST
  durationList: [
    {
      text: 'Daily',
      value: 'daily',
      multiplierFactor: 1,
    },
    {
      text: 'Weekly',
      value: 'weekly',
      multiplierFactor: 7,
    },
    {
      text: 'Monthly',
      value: 'monthly',
      multiplierFactor: 30,
    },
    {
      text: 'Yearly',
      value: 'yearly',
      multiplierFactor: 365,
    },
  ],

  // FORMS - ITEMS PAGE
  formTypeList: [ 'Text', 'Date and Time', 'Images', 'Signature', 'Location' ],

  // INFO
  dashboardType: dashboardType,
  dashboardTypePublic: dashboardTypePublic,
  dashboardTypeTmmin: dashboardTypeTmmin,
  dashboardTypePidi: dashboardTypePidi,
  dashboardTypeKedaireka: dashboardTypeKedaireka,

  // MAP
  mapInitialLocation: [ -0.7893, 113.9213 ],
  mapInitialZoom: 4,

  // PAGINATION
  rowsPerPageOptions: [ 5, 10, 25, 50, 100 ],

  // PERIOD PICKERS
  commandOptionList: [
    'Select Date',
    // 'Select Week',
  ],

  // SETTINGS
  fontFamily: [ 'Montserrat', 'Rubik', 'Roboto', 'sans-serif' ].join(','),
  languageList: [
    {
      code: 'EN',
      flag: 'https://flagcdn.com/w20/us.png',
      text: 'English',
    },
    {
      code: 'ID',
      flag: 'https://flagcdn.com/w20/id.png',
      text: 'Indonesia',
    },
  ],
  themeLight: 'light',
  themeDark: 'dark',

  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

  // TRACEABILITY PAGE - TRACK STATUS
  trackStatus: {
    success: 'Success',
    inProcess: 'In Process',
    failed: 'Failed',
  },
  // TRACEABILITY PAGE - TRACK CATEGORY
  trackCategory: {
    part: 'Part',
    vehicle: 'Vehicle',
  },
}