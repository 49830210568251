// I18NEXTS
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// LANGUAGES
import english from 'languages/english'
import indonesia from 'languages/indonesia'

// UTILS
import { readLanguageFromLocalStorage } from 'utils/localStorage'

const resources = {
  'EN': english,
  'ID': indonesia,
}

const initialSelectedLanguage = readLanguageFromLocalStorage().code

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: initialSelectedLanguage,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n