import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// COMPONENTS
import App from './App'
import GlobalStyles from 'components/GlobalStyles/GlobalStyles'

// MUIS
import { 
  createTheme,
  StyledEngineProvider, 
  ThemeProvider, 
} from '@mui/material/styles'

// STYLES
import './index.css'

// UTILS
import 'utils/localization'

// CONTEXTS
const AllPagesContextProvider = lazy(() => import('contexts/AllPagesContext'))

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <ThemeProvider theme={createTheme({})}>
    <StyledEngineProvider injectFirst>
      <AllPagesContextProvider>
        <BrowserRouter>
          <GlobalStyles/>
          <App />
        </BrowserRouter>
      </AllPagesContextProvider>
    </StyledEngineProvider>
  </ThemeProvider>
)