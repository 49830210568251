// CONSTANTS
import { values } from 'constants/values'

// MUIS
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let initialTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        sx: {
          textTransform: 'none',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        sx: {
          '& .MuiTypography-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        sx: {
          '& .MuiPaper-root': {
            minWidth: 288,
          },
          '& .MuiAlert-icon': {
            alignSelf: 'center',
          },
        },
      },
    },
  },
  palette: {
    additional: {
      scrollbar: '',
      scrollbarThumb: '',
      iconButtonHover: '',
    },
  },
  typography: {
    fontFamily: values.fontFamily,
  },
})

initialTheme = responsiveFontSizes(initialTheme) 

export default initialTheme