
// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'

const GlobalStyles = () => {
  // const zoomValue = 0.85

  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: values.fontFamily,
          pageBreakInside: 'avoid',
        },

        // COMPONENT - APEX CHART
        '.apexChartTooltip': {
          '& .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .apexcharts-tooltip.apexcharts-theme-light': {
            backgroundColor: theme.palette.background.default,
          },
        },

        // COMPONENT - AUTOCOMPLETE
        '.autocompleteRoot': {
          '& .MuiOutlinedInput-root': {
            padding: 0,
            paddingLeft: 8,
            fontSize: 14,
          },
        },
        '.autocompleteTextField': {
          '& .MuiOutlinedInput-root': {
            height: 44,
            borderRadius: 8,
          },
        },
        // COMPONENT - DATA GRID
        '.dataGridRowHeightAuto': {
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { 
            paddingTop: 8,
            paddingBottom: 8, 
          },
        },
        // COMPONENT - DATE RANGE PICKER
        '& .MuiPickerStaticWrapper-root': {
          minWidth: 'unset',
        },
        // COMPONENT - LEAFLET
        '&.leaflet-container': {
          width: '100%',
          height: '100%',
        },

        // GENERAL STYLES
        '.alignItemsUnset': {
          alignItems: 'unset'
        },
        '.alignSelfCenter': {
          alignSelf: 'center !important',
        },
        '.backgroundColorErrorMain': {
          backgroundColor: theme.palette.error.main,
        },
        '.backgroundColorSuccessLight': {
          backgroundColor: theme.palette.success.light,
        },
        '.colorActionActive': {
          color: `${theme.palette.action.active} !important`,
        },
        '.colorPrimaryMain': {
          color: `${theme.palette.primary.main} !important`,
        },
        '.colorTextPrimary': {
          color: `${theme.palette.text.primary} !important`,
        },
        '.colorTextSecondary': {
          color: `${theme.palette.text.secondary} !important`,
        },
        '.displayFlex': {
          display: 'flex !important',
        },
        '.fontWeight400': {
          fontWeight: '400 !important',
        },
        '.fontWeight600': {
          fontWeight: '600 !important',
        },
        '.justifyContentCenter': {
          justifyContent: 'center !important',
        },
        '.marginLeftAuto': {
          marginLeft: 'auto !important',
        },
        '.marginRightAuto': {
          marginRight: 'auto !important',
        },
        '.minHeightUnset': {
          minHeight: 'unset !important',
        },
        '.minWidthFitContent': {
          minWidth: 'fit-content !important',
        },
        '.widthFitContent': {
          width: 'fit-content !important',
        },
        '.widthFullWidth': {
          width: '100% !important',
        },
        '.wordBreakBreakWord': {
          wordBreak: 'break-word !important',
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 8,
          height: 8,
          backgroundColor: theme.palette.additional.scrollbar,
          borderRadius: 8,
        },
        '&::-webkit-scrollbar-thumb': {
          width: 8,
          height: 8,
          backgroundColor: theme.palette.additional.scrollbarThumb,
          borderRadius: 8,
        },

        // ZOOM
        '@media only screen and (max-height: 800px) and (min-width: 1200px)': {
          'body': {
            // zoom: zoomValue,
          },
          '.zoom': {
            // zoom: zoomValue,
          },
          '.no-zoom': {
            // zoom: 1 / zoomValue,
          },
        },
      })}
    />
  )
}

export default GlobalStyles