export const getUserRole = async (
  inputAxiosPrivate,
  inputSignal,
  inputUsername,
) => {
  try {
    const response = await inputAxiosPrivate.get(
      `/user-mgmt/v1/user-role/${inputUsername}`,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const tmminGetUserProfile = async (
  inputAxiosPrivate,
  inputSignal,
) => {
  try {
    const response = await inputAxiosPrivate.get(
      '/user-mgmt/v1/user-profile',
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}