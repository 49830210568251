// MUIS
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const CustomTooltipContainer = styled(({ className, ...props }) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }} 
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'unset',
    padding: 0,
    margin: 0,
    fontSize: 'unset',
  },
}))

export default CustomTooltipContainer