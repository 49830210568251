import { Suspense, useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

// COMPONENTS
import AuthenticationRoute from 'components/Routes/AuthenticationRoute'
import BackdropLoading from 'components/BackdropLoading/BackdropLoading'
import PrivateRoute from 'components/Routes/PrivateRoute'
import Snackbar from 'components/Snakbar/Snackbar'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// ROUTES
import routes from 'routes/routes'

// UTILITIES
import { getCompanyLogo } from 'utils/assets'
import { getBrowserTheme } from 'utils/theme'

const App = () => {
  const { 
    isBackdropLoadingOpen,
    snackbarObject, setSnackbarObject,
  } = useContext(AllPagesContext)

  const getRouteComponent = (inputItem) => {
    if (inputItem.routeType === 'authentication') {
      return (
        <AuthenticationRoute>
          {inputItem.element}
        </AuthenticationRoute>
      )
    }
    else if (inputItem.routeType === 'private') {
      return (
        <PrivateRoute>
          {inputItem.element}
        </PrivateRoute>
      )
    }
    else return inputItem.element
  }

  // UPDATE FAVICON
  useEffect(() => {
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = getCompanyLogo('icon', getBrowserTheme())
  }, [])

  return (
    <Suspense fallback={<div/>}>
      {/* ROUTES */}
      <Routes>
        {routes.map((item, index) => (
          <Route 
            key={index}
            path={item.path} 
            element={getRouteComponent(item)}
          />
        ))}
      </Routes>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />

      {/* BACKDROP LOADING */}
      <BackdropLoading open={isBackdropLoadingOpen}/>
    </Suspense>
  )
}

export default App