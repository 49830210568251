import PropTypes from 'prop-types'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './authenticationUseStyles'

const Authentication = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <Box className={`${classes.root} no-zoom`}>
      <Box className={`${classes.childrenContainer} zoom`}>
        {children}
      </Box>
    </Box>
  )
}

Authentication.defaultProps = {}

Authentication.propTypes = { children: PropTypes.node.isRequired }

export default Authentication