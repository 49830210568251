// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    '& .MuiPaper-root': {
      borderRadius: 8,
    },
    '& .MuiAlert-icon': {
      alignSelf: 'flex-start',
    },
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  message: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,
  },
}))

export default useStyles