// ASSETS
import BackgroundAuthentication from 'assets/images/backgrounds/authentication.jpg'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  childrenContainer: {
    flex: 1,
    height: '100%',
    backgroundImage: `url(${BackgroundAuthentication})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
    [theme.breakpoints.only('lg')]: {
      padding: 32,
    },
    [theme.breakpoints.only('md')]: {
      padding: 28,
    },
    [theme.breakpoints.only('sm')]: {
      padding: 24,
    },
    [theme.breakpoints.only('xs')]: {
      padding: 20,
    },
  },
}))

export default useStyles