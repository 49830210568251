// CONSTANTS
import { values } from 'constants/values'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  avatarRoot: {
    position: 'relative',
    width: 36,
    height: 36,
    backgroundColor: 'transparent',
  },
  avatarBadge: {
    '& .MuiBadge-badge':{
      backgroundColor: theme.palette.secondary.main,
      border: '1px solid white',
      padding: 2,
      color: theme.palette.common.white,
    },
  },
  tooltipRoot: {
    '& .MuiPaper-root': {
      width: 300,
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
  tooltipTitle: {
    fontWeight: 600,
    padding: 20,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  notificationItem: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === values.themeLight 
      ? theme.palette.grey[100]
      : theme.palette.grey[800],
    border: `1px solid ${theme.palette.divider}`,
  },
  notificationItemTexts: {
    marginRight: 4,
  },
  noNotificationText: {
    padding: 12,
    textAlign: 'center',
  },
}))

export default useStyles