// CONSTANTS
import { colors } from './colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const primaryMain = '#F3C903'
const white = '#FFFFFF'

export const colorsKedaireka = {
  // // PRIMARY
  primary: {
    main: primaryMain, // YELLOW
    dark: '#CFAC02', // DARK YELLOW
    light: '#FCDF53', // LIGHT YELLOW
  },
  // SECONDARY
  secondary: colors.secondary,
  // ERROR
  error: colors.error,
  // SUCCESS
  success: colors.success,
  // WARNING
  warning: colors.warning,
  // INFO
  info: colors.info,
  // TEXT
  text: {
    primary: '#E0E6ED', // WHITE
    secondary: alpha('#E0E6ED', 0.54), // GREY
  },
  // DIVIDER
  divider: alpha(white, 0.16),
  // BACKGROUND
  background: {
    default: '#060818', // BLACK
    paper: '#032452', // DARK BLUE
  },
  // ACTION
  action: {
    active: alpha(white, 0.6), // GREY
    hover: alpha(primaryMain, 0.08), // PRIMARY
    disabled: alpha(white, 0.24), // GREY
    selected: alpha(primaryMain, 0.2), // PRIMARY
  },
  // ADDITIONAL
  additional: {
    scrollbar: grey[800], // LIGHT GREY
    scrollbarThumb: grey[600], // LIGHT GREY
    iconButtonHover: alpha(white, 0.16), // LIGHT GREY
  },
}