import { lazy } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
const PageDashboardContextProvider = lazy(() => import('contexts/PageDashboardContext'))
const PageTraceabilityTmminContextProvider = lazy(() => import('contexts/PageTraceabilityTmminContext'))
const PageTraceabilityDetailTmminContextProvider = lazy(() => import('contexts/PageTraceabilityDetailTmminContext'))

// PAGES
const CarbonFootprint = lazy(() => import('pages/CarbonFootprint/CarbonFootprint'))
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'))
const Forms = lazy(() => import('pages/Forms/Forms'))
const FormsAddOrEdit = lazy(() => import('pages/FormsAddOrEdit/FormsAddOrEdit'))
const History = lazy(() => import('pages/History/History'))
// const Orders = lazy(() => import('pages/Orders/Orders'))
// const OrdersAddOrEdit = lazy(() => import('pages/OrdersAddOrEdit/OrdersAddOrEdit'))
const Privacy = lazy(() => import('pages/Privacy/Privacy'))
const TraceabilityDetailPidi = lazy(() => import('pages/TraceabilityDetail/Pidi/TraceabilityDetail'))
const TraceabilityDetailTmmin = lazy(() => import('pages/TraceabilityDetail/Tmmin/TraceabilityDetail'))
const TraceabilityPidi = lazy(() => import('pages/Traceability/Pidi/Traceability'))
const TraceabilityTmmin = lazy(() => import('pages/Traceability/Tmmin/Traceability'))
const Trackability = lazy(() => import('pages/Trackability/Trackability'))
const Transactions = lazy(() => import('pages/Transactions/Transactions'))

// FORMS ITEMS PAGES
const formsItemsPageList = [
  // LIST OF ITEMS
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/items',
    element: <Forms/>,
    routeType: 'private',
  },
  // ADD AN ITEM
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/items/add',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
  // EDIT AN ITEM
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/items/edit/:id',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
]

// FORMS ACTORS PAGES
const formsActorsPageList = [
  // LIST OF ACTORS
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/actors',
    element: <Forms/>,
    routeType: 'private',
  },
  // ADD AN ACTOR
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/actors/add',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
  // EDIT AN ACTOR
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/actors/edit/:id',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
]

// FORMS TOUCH POINTS PAGES
const formsTouchPointsPageList = [
  // LIST OF TOUCH POINTS
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/touch-points',
    element: <Forms/>,
    routeType: 'private',
  },
  // ADD A TOUCH POINT
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/touch-points/add',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
  // EDIT A TOUCH POINT
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/touch-points/edit/:id',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
]

// FORM WORKFLOWS PAGES
const formsWorkflowsPageList = [
  // LIST OF WORKFLOWS
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/workflows',
    element: <Forms/>,
    routeType: 'private',
  },
  // ADD A WORKFLOW
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/workflows/add',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
  // EDIT A WORKFLOW
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forms/workflows/edit/:id',
    element: <FormsAddOrEdit/>,
    routeType: 'private',
  },
]

// TRACEABILITY PAGES
const traceabilityPageList = [
  // KEDAIREKA, PIDI, AND PUBLIC TRACEABILITY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypePidi) && {
    path: '/traceability',
    element: <TraceabilityPidi/>,
    routeType: 'private',
  },
  // KEDAIREKA, PIDI, AND PUBLIC DETAIL TRACEABILITY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypePidi) && {
    path: '/traceability/detail',
    element: <TraceabilityDetailPidi/>,
    routeType: 'private',
  },
  // TMMIN TRACEABILITY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypeTmmin) && {
    path: '/traceability',
    element: (
      <PageTraceabilityTmminContextProvider>
        <TraceabilityTmmin/>
      </PageTraceabilityTmminContextProvider>
    ),
    routeType: 'private',
  },
  // TMMIN DETAIL TRACEABILITY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka ||
  values.dashboardType === values.dashboardTypeTmmin) && {
    path: '/traceability/detail',
    element: (
      <PageTraceabilityDetailTmminContextProvider>
        <TraceabilityDetailTmmin/>
      </PageTraceabilityDetailTmminContextProvider>
    ),
    routeType: 'private',
  },
]

// ORDERS
// const ordersPageList = [
//   // ORDERS
//   values.dashboardType === values.dashboardTypePidi &&
//   {
//     path: '/orders',
//     element: <Orders/>,
//     routeType: 'private',
//   },
//   // CREATE AN ORDER
//   values.dashboardType === values.dashboardTypePidi &&
//   {
//     path: '/orders/create',
//     element: <OrdersAddOrEdit/>,
//     routeType: 'private',
//   },
// ]

export const privateRouteList = [
  // DASHBOARD
  {
    path: '/dashboard',
    element: (
      <PageDashboardContextProvider>
        <Dashboard/>
      </PageDashboardContextProvider>
    ),
    routeType: 'private',
  },
  // CARBON FOOTPRINT
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka) && {
    path: '/carbon-footprint',
    element: <CarbonFootprint/>,
    routeType: 'private',
  },
  // TRACEABILITY
  ...traceabilityPageList,
  // PRIVACY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeTmmin) && {
    path: '/privacy',
    element: <Privacy/>,
    routeType: 'private',
  },
  // TRACKABILITY
  (values.dashboardType === values.dashboardTypePublic ||
  values.dashboardType === values.dashboardTypeKedaireka) && {
    path: '/trackability',
    element: <Trackability/>,
    routeType: 'private',
  },
  // HISTORY
  values.dashboardType !== values.dashboardTypePidi &&
  {
    path: '/history',
    element: <History/>,
    routeType: 'private',
  },
  // FORMS - ITEMS
  ...formsItemsPageList,
  // FORMS - ACTORS
  ...formsActorsPageList,
  // FORMS - TOUCH POINTS
  ...formsTouchPointsPageList,
  // FORMS - WORKFLOWS
  ...formsWorkflowsPageList,
  // TRANSACTIONS
  values.dashboardType === values.dashboardTypePublic && {
    path: '/transactions',
    element: <Transactions/>,
    routeType: 'private',
  },
  // ORDERS
  // ...ordersPageList,
]