import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// SERVICES
import { tmminPostRefreshToken } from 'services/tmmin/identity'

// UTILS
import { signOutUser } from 'utils/authentication'

const useTmminRefreshToken = () => {
  const { auth, setAuth } = useContext(AllPagesContext)

  const tmminRefreshToken = async () => {
    const responseRefreshToken = await tmminPostRefreshToken(
      auth.userRole.email,
      auth.refreshToken,
    )

    if (responseRefreshToken.status === 200) {
      setAuth(current => {
        return {
          ...current,
          accessToken: responseRefreshToken.data.data.accessToken,
        }
      })

      return responseRefreshToken.data.data.accessToken
    }
    else signOutUser(setAuth)
  }

  return tmminRefreshToken
}

export default useTmminRefreshToken