// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tooltipPaper: {
    padding: 16,
    borderRadius: 16,
    boxShadow: `0 0 10px ${alpha('#000000', 0.12)}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 240,
  },
  tooltipAvatar: {
    height: 60,
    width: 60,
    borderRadius: 16,
    marginBottom: 16,
  },
  tooltipRole: {
    marginTop: 4,
    marginBottom: 8,
  },
  tooltipItemIcon: {
    minWidth: 'unset',
    marginRight: 12,
  },
  tooltipLogOutButton: {
    marginTop: 8,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  userProfileContainer: {
    alignItems: 'center',
    marginLeft: 8,
    cursor: 'pointer',
  },
  userProfileAvatar: {
    height: 36,
    width: 36,
    cursor: 'pointer',
    borderRadius: 8,
  },
  userProfileTextContainer: {
    maxWidth: 160,
    fontWeight: 600,
  },
  userProfileText: {
    maxHeight: 44,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
}))

export default useStyles