// ASSETS
import CompanyLogoKedairekaFull from 'assets/images/logos/company-kedaireka-full.svg'
import CompanyLogoKedairekaIcon from 'assets/images/logos/company-kedaireka-icon.svg'
import CompanyLogoPidiFullBlack from 'assets/images/logos/company-pidi-full-black.svg'
import CompanyLogoPidiFullWhite from 'assets/images/logos/company-pidi-full-white.svg'
import CompanyLogoPidiIconBlack from 'assets/images/logos/company-pidi-icon-black.svg'
import CompanyLogoPidiIconWhite from 'assets/images/logos/company-pidi-icon-white.svg'
import CompanyLogoPublicFull from 'assets/images/logos/company-bloxchain-full.svg'
import CompanyLogoPublicIcon from 'assets/images/logos/company-bloxchain-icon.svg'
import CompanyLogoToyotaFull from 'assets/images/logos/company-toyota-full.png'

// CONSTANTS
import { values } from 'constants/values'

export const getCompanyLogo = (
  inputType, 
  inputTheme = values.themeLight,
) => {
  // KEDAIREKA
  if (values.dashboardType === values.dashboardTypeKedaireka) {
    // FULL
    if (inputType === 'full') {
      if (inputTheme === values.themeLight) return CompanyLogoKedairekaFull
      else if (inputTheme === values.themeDark) return CompanyLogoKedairekaFull
    }
    // ICON
    else if (inputType === 'icon') {
      if (inputTheme === values.themeLight) return CompanyLogoKedairekaIcon
      else if (inputTheme === values.themeDark) return CompanyLogoKedairekaIcon
    }
  }
  // PIDI
  else if (values.dashboardType === values.dashboardTypePidi) {
    // FULL
    if (inputType === 'full') {
      if (inputTheme === values.themeLight) return CompanyLogoPidiFullBlack
      else if (inputTheme === values.themeDark) return CompanyLogoPidiFullWhite 
    }
    // ICON
    else if (inputType === 'icon') {
      if (inputTheme === values.themeLight) return CompanyLogoPidiIconBlack
      else if (inputTheme === values.themeDark) return CompanyLogoPidiIconWhite 
    }
  }
  // PUBLIC
  else if (values.dashboardType === values.dashboardTypePublic) {
    if (inputType === 'full') return CompanyLogoPublicFull
    else if (inputType === 'icon') return CompanyLogoPublicIcon
  }
  // TMMIN
  else if (values.dashboardType === values.dashboardTypeTmmin) return CompanyLogoToyotaFull 
}