import { lazy } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// PAGES
const Error404 = lazy(() => import('pages/Error404/Error404')) 
const LandingKedaireka = lazy(() => import('pages/Landing/Kedaireka/Landing'))
const LandingPidi = lazy(() => import('pages/Landing/Pidi/Landing')) 
const LandingPublic = lazy(() => import('pages/Landing/Public/Landing')) 
const LandingTmmin = lazy(() => import('pages/Landing/Tmmin/Landing')) 

export const freeRouteList = [
  // LANDING PAGE (KEDAIREKA)
  values.dashboardType === values.dashboardTypeKedaireka && {
    path: '/',
    element: <LandingKedaireka/>,
    routeType: 'free',
  },
  // LADING PAGE (PIDI)
  values.dashboardType === values.dashboardTypePidi && {
    path: '/',
    element: <LandingPidi/>,
    routeType: 'free',
  },
  // LADING PAGE (PUBLIC)
  values.dashboardType === values.dashboardTypePublic && {
    path: '/',
    element: <LandingPublic/>,
    routeType: 'free',
  },
  // LADING PAGE (TMMIN)
  values.dashboardType === values.dashboardTypeTmmin && {
    path: '/',
    element: <LandingTmmin/>,
    routeType: 'free',
  },
  // NOT FOUND
  {
    path: '/*',
    element: <Error404/>,
    routeType: 'free',
  },
]