import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import ChangeLanguage from 'components/ChangeLanguage/ChangeLanguage'
import ToggleTheme from 'components/ToggleTheme/ToggleTheme'
import TooltipNotificationList from './TooltipNotificationList/TooltipNotificationList'
import TooltipIconButton from 'components/TooltipIconButton/TooltipIconButton'
import TooltipUserProfile from './TooltipUserProfile/TooltipUserProfile'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext'

// MUIS
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconFullscreen from '@mui/icons-material/Fullscreen'
import IconMenuOpen from '@mui/icons-material/MenuOpen'
import IconSearchRounded from '@mui/icons-material/SearchRounded'

// SERVICES
import { postTrackTriggerAbnormalNotification } from 'services/blockchainContract/track'

// STYLES
import useStyles from './appBarUseStyles'

// UTILITIES
import { convertDate } from 'utils/date'

const Appbar = () => {
  const classes = useStyles()

  const { 
    breakpointType,
    auth, 
    setSnackbarObject,
  } = useContext(AllPagesContext)
  const { 
    appBarPageTitle, 
    appBarIsFullScreen, setAppBarIsFullScreen,
    setIsDrawerExpanded,
    setMustReloadLayout,
    handleFullScreen,
  } = useContext(LayoutPrivateContext)

  const [ realTimeClock, setRealTimeClock ] = useState(new Date())

  const isPublicOrTmminDashboardType = values.dashboardType === values.dashboardTypePublic || values.dashboardType === values.dashboardTypeTmmin

  const handleFullScreenButtonClick = () => {
    if (appBarIsFullScreen) {
      setAppBarIsFullScreen(false)
      handleFullScreen.exit()
    }
    else {
      setAppBarIsFullScreen(true)
      handleFullScreen.enter()
    }
  }

  // TRIGGER ABNORMAL NOTIFICATION FOR PIDI DASHBOARD
  const handleTextClick = async (inputType) => {
    if (values.dashboardType === values.dashboardTypePidi) {
      const abortController = new AbortController()
      
      const resultTriggerAbnormalNotification = await postTrackTriggerAbnormalNotification(
        abortController.signal,
        auth?.accessToken,
        inputType,
      )

      if (resultTriggerAbnormalNotification.status === 400) {
        const data = resultTriggerAbnormalNotification?.data?.data

        let warningTitle = 'Create Track Failed - '

        if (data?.includes('Material')) warningTitle = `${warningTitle}Material Number`
        else if (data?.includes('Quantity')) warningTitle = `${warningTitle}Quantity`

        setMustReloadLayout(true)

        setSnackbarObject({
          open: true,
          severity: 'warning',
          title: warningTitle,
          message: data,
        })
      }

      abortController.abort()
    }
  }

  useEffect(() => {
    const updateCurrentDateAndTime = setInterval(() => setRealTimeClock(new Date()), 1000)

    return(() => {
      clearInterval(updateCurrentDateAndTime)
    })
  }, [])

  return (
    <MuiAppBar className={`${classes.appBarRoot} zoom`}>
      <Toolbar className={classes.toolbar}>
        <Stack 
          direction='row'
          width='100%'
          alignItems='center'
          columnGap='4px'
        >
          {(breakpointType === 'xs' || breakpointType === 'sm' || breakpointType === 'md') &&
          <TooltipIconButton 
            title='Toggle Drawer' 
            onClick={() => setIsDrawerExpanded(current => !current)}
          >
            <IconMenuOpen fontSize='small'/>
          </TooltipIconButton>}

          {/* PAGE TITLE */}
          <Typography
            variant='h5'
            className={classes.pageTitle}
            onClick={() => handleTextClick('qr_code')}
          >
            {appBarPageTitle}
          </Typography>

          {/* REAL TIME CLOCK */}
          {values.dashboardType === values.dashboardTypePidi &&
          <Typography 
            variant={(breakpointType === 'md' || breakpointType === 'lg' || breakpointType === 'xl') ? 'subtitle1' : 'body2'}
            color='text.primary'
            className={classes.clockText}
            onClick={() => handleTextClick('quantity')}
          >
            {(breakpointType === 'lg' || breakpointType === 'xl') ?
              convertDate(realTimeClock) : 
              (<>
                <>{convertDate(realTimeClock, 'dd MMM yyyy')}</>
                <br/>
                <>{convertDate(realTimeClock, 'HH:mm:ss')}</>
              </>)}
          </Typography>}

          {/* SEARCH BUTTON */}
          {isPublicOrTmminDashboardType &&
          <TooltipIconButton title='Search'>
            <IconSearchRounded fontSize='small'/>
          </TooltipIconButton>}

          {/* MENU NOTIFICATION LIST */}
          <TooltipNotificationList/>

          {/* TOGGLE THEME */}
          {isPublicOrTmminDashboardType &&
          <Box marginRight='4px'>
            <ToggleTheme/>
          </Box>}

          {/* CHANGE LANGUAGE */}
          {isPublicOrTmminDashboardType &&
          <ChangeLanguage/>}

          {/* TOGGLE FULL SCREEN BUTTON */}
          <TooltipIconButton 
            title='Toggle Full Screen'
            onClick={handleFullScreenButtonClick}
          >
            <IconFullscreen fontSize='small'/>
          </TooltipIconButton>

          {/* TOOLTIP USER PROFILE */}
          <TooltipUserProfile/>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  )
}

export default Appbar