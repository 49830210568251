const indonesia = {
  translation: {
    // GENERAL VIEW
    username: 'Nama pengguna',
    inputYourUsername: 'Masukan nama pengguna anda di sini',
    password: 'Kata Sandi',
    inputYourPassword: 'Masukan kata sandi anda di sini',
    role: 'Peran',
    inputSelectRole: 'Pilih satu peran di sini',
    admin: 'Admin',
    supplier: 'Pemasok',
    manufacture: 'Pembuat',
    distributor: 'Penyalur',
    government: 'Pemerintah',
    emailAddress: 'Alamat email',
    inputYourEmailAddress: 'Masukan alamat email anda di sini',
    confirmPassword: 'Konfirmasi kata sandi',
    inputConfirmPassword: 'Masukan ulang kata sandi anda di sini',
    newPassword: 'Kata sandi baru',
    inputNewPassword: 'Masukan kata sandi baru di sini',
    login: 'Masuk',
    backTo: 'Kembali ke',
    showPassword: 'Tunjukan kata sandi',
    hidePassword: 'Sembunyikan kata sandi',
    // GENERAL FEEDBACK
    emptyFieldValidation: 'Mohon isi semua kolom',
    noServerResponse: 'Server tidak merespon',
    passwordAtLeast: 'Kata sandi minimal 8 karakter',
    passwordDoenstMatchConfirm: 'Kata sandi dan konfirmasi kata sandi tidak sama',
    copiedToClipboard: 'Berhasil disalin',
    successChangeLanguage: 'Sukses mengubah bahasa',
    // SIGN IN PAGE
    signInPageTitle: 'Masuk ke dalam akun',
    signInPageCaption: 'Masukan nama pengguna anda dan kata sandi untuk masuk',
    signInForgotPassword: 'Lupa password?',
    signInButton: 'Masuk',
    signInDontHaveAccount: 'Belum punya akun?',
    signInCreateAccount: 'Buat akun baru',
    signInSuccess: 'Berhasil masuk, selamat datang',
    signInErrorUnregisteredUsername: 'Nama pengguna tidak terdaftar',
    signInErrorWrongPassword: 'Kata sandi salah',
    signInErrorBadCredentials: 'Nama pangguna atau kata sandi salah',
    signInExpiredSubscription: 'Langganan berakhir',
    // SIGN UP PAGE
    signUpPageTitle: 'Buat akun anda',
    signUpPageCaption: 'Masukan info anda untuk membuat akun',
    signUpButton: 'Buat akun',
    signUpAlreadyHaveAccount: 'Sudah punya akun?',
    signUpLogin: 'Masuk',
    signUpSuccess: 'Sukses membuat akun baru',
    signUpErrorTakenUsername: 'Nama pengguna sudah dipakai',
    signUpErrorTakenEmail: 'Alamat email sudah dipakai',
    // FORGOT PASSWORD PAGE
    forgotPasswordTitle: 'Lupa kata sandi',
    forgotPasswordCaption: 'Masukan email anda dan kami akan mengirim instruksi untuk mengubah kata sandi anda',
    forgotPasswordButton: 'Kirim instruksi',
    forgotPasswordBack: 'Kembali ke',
    forgotPasswordLogin: 'Masuk',
    forgotPasswordSuccess: 'Instruksi sudah dikirim ke email anda',
    forgotPasswordUnregisteredEmail: 'Email tidak terdaftar',
    // RESET PASSWORD PAGE
    resetPasswordTitle: 'Mengganti kata sandi',
    resetPasswordCaption: 'Masukan kata sandi baru untuk mengganti kata sandi lama',
    resetPasswordChangeEmail: 'Ganti alamat email',
    resetPasswordButton: 'Ganti kata sandi',
    resetPasswordBack: 'Kembali ke',
    resetPasswordLogin: 'Masuk',
    resetPasswordSuccess: 'Kanta sandi anda berhasil diganti',
    resetPasswordExpiredToken: 'Token kedaluarsa, mohon mengirim ulang permintaan',
  },
}

export default indonesia