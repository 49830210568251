// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha(theme.palette.common.white, 0.4),
    zIndex: theme.zIndex.modal + 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    '&.MuiCircularProgress-indeterminate': {
      width: '200px !important',
      height: '200px !important',
    },
  },
}))

export default useStyles