import { lazy } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// PAGES
const ForgotPassword = lazy(() => import('pages/ForgotPassword/ForgotPassword')) 
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPassword')) 
const SignIn = lazy(() => import('pages/SignIn/SignIn')) 
const SignUp = lazy(() => import('pages/SignUp/SignUp')) 

export const authenticationRouteList = [
  // SIGN IN
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  // SIGN UP
  values.dashboardType === values.dashboardTypePublic && {
    path: '/sign-up',
    element: <SignUp/>,
    routeType: 'authentication',
  },
  // FORGOT PASSWORD
  values.dashboardType === values.dashboardTypePublic && {
    path: '/forgot-password',
    element: <ForgotPassword/>,
    routeType: 'authentication',
  },
  // RESET PASSWORD
  values.dashboardType === values.dashboardTypePublic && {
    path: '/reset-password/token=:resetTokenId',
    element: <ResetPassword/>,
    routeType: 'authentication',
  },
]