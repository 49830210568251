// CONSTANTS
import { colors } from './colors'

// MUIS
import { alpha } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const white = '#FFFFFF'

export const colorsPidi = {
  // PRIMARY
  primary: colors.primary,
  // SECONDARY
  secondary: colors.secondary,
  // ERROR
  error: colors.error,
  // SUCCESS
  success: colors.success,
  // WARNING
  warning: colors.warning,
  // INFO
  info: colors.info,
  // TEXT
  text: {
    primary: '#E0E6ED', // WHITE
    secondary: alpha('#E0E6ED', 0.54), // GREY
  },
  // DIVIDER
  divider: alpha(white, 0.12),
  // BACKGROUND
  background: {
    default: '#1A1B49', // DARK BLUE
    paper: '#0F1132', // BLACK BLUE
  },
  // ACTION
  action: {
    active: alpha(white, 0.54), // GREY
    hover: alpha(colors.primary.main, 0.2), // PRIMARY
    disabled: alpha(white, 0.24), // GREY
    selected: alpha(colors.primary.main, 0.2), // PRIMARY
  },
  // ADDITIONAL
  additional: {
    scrollbar: grey[800], // LIGHT GREY
    scrollbarThumb: grey[600], // LIGHT GREY
    iconButtonHover: alpha(white, 0.16), // LIGHT GREY
  },
}