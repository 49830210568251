// MUIS
import { alpha } from '@mui/material/styles'
import { blueGrey, grey } from '@mui/material/colors'

const primaryMain = '#7366FF'
const black = '#000000'
const white = '#FFFFFF'

export const colors = {
  // PRIMARY
  primary: {
    main: primaryMain, // PURPLE
    dark: '#4236C8', // DARK PURPLE
    light: '#AEA7FF', // LIGHT PURPLE
  },
  // SECONDARY
  secondary: {
    main: '#F73164', // RED
    dark: '#BB123D', // DARK RED
    light: '#F590AA', // LIGHT RED
  },
  // ERROR
  error: {
    main: '#F44336', // RED
    dark: '#C63128', // DARK RED
    light: '#F28880', // LIGHT RED
  },
  // SUCCESS
  success: {
    main: '#388E3C', // GREEN
    dark: '#006608', // DARK GREEN
    light: '#70C874', // LIGHT GREEN
  },
  // WARNING
  warning: {
    main: '#FFAC09', // ORANGE
    dark: '#F28100', // DARK ORANGE
    light: '#F8C86B', // LIGHT ORANGE
  },
  // INFO
  info: {
    main: '#00ABE3', // BLUE
    dark: '#008CBA', // DARK BLUE
    light: '#72CBE8', // LIGHT BLUE
  },
}

export const colorsLight = {
  // TEXT
  text: {
    primary: '#2B2B2B', // BLACK
    secondary: alpha('#2B2B2B', 0.54), // GREY
  },
  // DIVIDER
  divider: alpha(black, 0.12),
  // BACKGROUND
  background: {
    default: '#F8F8F8', // LIGHT GREY
    paper: white, // WHITE
  },
  // ACTION
  action: {
    active: alpha(black, 0.54), // GREY
    hover: grey[100], // LIGHT GREY
    disabled: alpha(black, 0.24), // GREY
  },
  // ADDITIONAL
  additional: {
    scrollbar: grey[200], // LIGHT GREY
    scrollbarThumb: grey[400], // LIGHT GREY
    iconButtonHover: alpha(primaryMain, 0.16), // LIGHT PURPLE
  },
}

export const colorsDark = {
  // TEXT
  text: {
    primary: white, // WHITE
    secondary: alpha(white, 0.7), // GREY
  },
  // DIVIDER
  divider: alpha(white, 0.12),
  // BACKGROUND
  background: {
    default: '#1C1D26', // BLACK
    paper: '#262932', // LIGHT BLACK
  },
  // ACTION
  action: {
    active: alpha(white, 0.7), // GREY
    hover: alpha(primaryMain, 0.1), // LIGHT PURPLE
    disabled: alpha(white, 0.24), // LIGHT GREY
  },
  // ADDITIONAL
  additional: {
    scrollbar: grey[800], // LIGHT GREY
    scrollbarThumb: grey[600], // LIGHT GREY
    iconButtonHover: alpha(white, 0.16), // LIGHT GREY
  },
}